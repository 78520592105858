/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto'),
    url('../Fonts/Roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto'),
    url('../Fonts/Roboto/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto'),
    url('../Fonts/Roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
}
